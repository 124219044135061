import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"

import Layout from "../components/layout"
import SEO from "../components/SEOadvanced"
import Features from "../components/Features"
import Onmap from "../components/Onmap"
import Callback from "../components/Callback"

// import { FaCertificate } from "react-icons/fa"

const KontaktyPage = ({ data }) => (
  <>
    <SEO
      title="Контакти Б'юті-майстра в Білогородці"
      description="Знайти контакти салону в Білогородці. Телефон, адреса і графік роботи Б'юті-майстра в Білогородці. ЖК Білий Шоколад, Центр."
    />
    <Layout
      backgroundClass={`firstscreen kontakty`}
      serviceHeader1={`Привіт, я б'юті-майстер Оля`}
      serviceHeroText={`Роблю манікюр, педикюр і депіляцію воском. Приймаю в Білогородці. Працюю акуратно і чисто. Тебе чекає затишне крісло, чистий стіл, стерильний інструмент, комфортний співрозмовник і бездоганний результат.`}
      // ctaButtonText={`Зв'язатися`}
    >
      <section className="contactform py-5">
        <h2 className="mb-5 text-center uppercase">Як зв'язатися</h2>
        <Container className="mb-5">
          <p>
            Приймаю за попереднім записом в салоні "Le Beaute Studio" в ЖК Білий
            Шоколад.
            <br />
            Щоб записатися або щось запитати, будь-ласка, телефонуйте або
            відправте повідомлення. Якщо буду зайнята роботою, відповім в обід
            або ввечері. Теплу зустріч і красу обіцяю! 😃
          </p>
          <Form
            name="contact-form"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            method="post"
            action="/success/"
          >
            <input type="hidden" name="form-name" value="contact-form" />
            <Form.Group controlId="contactForm.ControlTextarea">
              <Form.Control
                required
                name="message-field"
                as="textarea"
                rows="3"
                placeholder="Напишіть ваше повідомлення..."
              />
            </Form.Group>
            <Form.Group controlId="contactForm.ControlContacts">
              <Row>
                <Col>
                  <Form.Control name="name-field" placeholder="Ім'я" />
                </Col>
                <Col>
                  <Form.Control
                    required
                    name="phone-field"
                    placeholder="Телефон для обратного зв'язку *"
                  />
                </Col>
              </Row>
            </Form.Group>
            <Button type="submit" variant="danger" size="lg">
              Надіслати повідомлення
            </Button>
          </Form>
        </Container>
      </section>
      <section className="certificates py-5">
        <h2 className="mb-5 text-center uppercase">
          Мої сертифікати та дипломи
        </h2>
        <Container className="mb-5">
          <Row>
            {data.contentfulGallery.images.map(image => (
              <Col key={image.id} lg={3} md={4} className="mb-4 h-100 d-block">
                <Img
                  fluid={image.fluid}
                  alt={image.title}
                  className="img-fluid"
                />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <Onmap />
      <Features />
      <Callback />
    </Layout>
  </>
)

export default KontaktyPage

export const query = graphql`
  {
    contentfulGallery(galeryName: { eq: "Сертифікати" }) {
      images {
        id
        title
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
